@charset "utf-8";
@import '../../setting';
@import '../../mixin';

/*
top
*/
$id: top;
$cmnPath:'../../../img/common/';
$imgPath:'../../../img/#{$id}/';

#wrapper {
    /* background: url(#{$imgPath}bg01.png) no-repeat center top / cover; */
    background: #FDFBFA;
    @include tabletP {
        background: none;
    }
}
#contents {
    @include tabletP {
        /* background: #FDFBFA url(#{$imgPath}bg01_sp.png) no-repeat center top / 100%; */
        background: #FDFBFA;
    }
}

/* 共通
====================================*/
.w-90 {
  
  max-width: 90%!important;
  @include tabletP {
    /* width: 100%; */
    margin: 0 auto 20px!important;
  }

}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.em_600 {
	font-weight: 600
}
.em_700 {
	font-weight: 700
}
.em_800 {
	font-weight: 800
}
.em_900 {
	font-weight: 900
}

.sec-title {
    font-family: 'Noto Serif JP', serif;
  }
  
  .sec-subtitle {
    font-family: 'Noto Sans JP', sans-serif;
  }
  
  .more-link {
    color: #3C379E;
    font-family: 'Noto Serif JP', serif;
    font-size: 14px;
    font-weight: bold;
    position: relative;
  
    &:hover {
      color: #FFA200;
    }
  
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: 5px;
      right: -35px;
      width: 30px;
      height: 1px;
      background: #3C379E;
    }
  
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: 0;
      right: -35px;
      width: 10px;
      height: 10px;
      border: 1px solid;
      border-color: transparent transparent #3C379E transparent;
      transform: rotate(-135deg);
    }
  
    &:hover {
      &::before {
        background: #FFA200 !important;
      }
  
      &::after {
        border-color: transparent transparent #FFA200 transparent !important;
      }
    }
  }
  
  .mail-link {
    background-color: #fff;
    border: 1px #3C379E solid;
    color: #3C379E;
    display: inline-block;
    padding: 10px;
    text-align: center;
    width: 280px;
    max-width: 100%;
  
    &:hover {
      background-color: #3C379E;
      border: 1px #fff solid;
      color: #fff;
      opacity: 1;
    }
    &:visited {
        color: #3C379E;
    }
  }
  
  .op-05 {
    opacity: .5;
  }
    /* 下層ページ
  ====================================*/
  #page {

      .page-title {
        margin-top: 20px;
        margin-bottom: 2em;
        text-align: center;
        color: #3c379e;

      }
  }
  